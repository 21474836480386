<template>
  <div :class="$style.container">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
    </Breadcrumb>
    <a-form ref="form" class="al-form" layout="inline" :model="form" :label-col="{ style: 'width: 80px' }"
            :wrapper-col="{ style: 'width: 170px' }">
      <a-form-item label="选择日期:"
                  style="width: 500px">
        <a-range-picker v-model="gmtInvite"
          :disabled-date="disabledDate"
          @change="onChange1"
          format="YYYY-MM-DD"
          valueFormat="YYYY-MM-DD"
          style="width: 400px" />
      </a-form-item>
      <a-form-item>
        <a-input v-model="form.name" placeholder="名称"/>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" :loading="isQueryLoading" @click="queryList">查询</a-button>
        <a-button @click="resetForm" style="margin-left:20px;">重置</a-button>
      </a-form-item>
    </a-form>

    <a-table
      :data-source="list"
      :rowKey="record => record.id"
      :columns="columns"
      :pagination="pagination"
      bordered
    >
      <span slot="action1" slot-scope="scope">
        <div>{{scope.phone}}</div>
        <div>{{ scope.name }}</div>
      </span>
      <span slot="action2" slot-scope="scope">
        <div style="color:#1890ff;">{{scope.accountType | formatAccountType}}</div>
      </span>
      <span slot="action" slot-scope="scope">
        <a @click="viewRecord(scope)">查看明细</a>
      </span>
    </a-table>
  </div>
</template>

<script>
import moment from 'moment'
import Breadcrumb from '@/components/breadcrumb.vue'

export default {
  components: {
    Breadcrumb
  },
  data () {
    return {
      breadList: [
        {
          name: '大使邀请统计管理',
          url: ''
        }
      ],
      isQueryLoading: false,
      gmtInvite: [],
      form: {
        name: '',
        gmtInviteStart: null,
        gmtInviteEnd: null
      },
      columns: [
        { title: '邀请人', width: 200, key: '', scopedSlots: { customRender: 'action1' } },
        { title: '用户类型', width: 200, key: 'accountType', scopedSlots: { customRender: 'action2' } },
        { title: '邀请注册大使数量', width: 200, key: 'registerTotal', dataIndex: 'registerTotal' },
        { title: '有销售记录大使数量', width: 200, key: 'orderCount', dataIndex: 'orderCount' },
        { title: '有完结销售记录大使数量', width: 200, key: 'orderFinishCount', dataIndex: 'orderFinishCount' },
        { title: '操作', key: 'operation', width: 200, scopedSlots: { customRender: 'action' } }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共 ${total} 条记录 第 ${range[0]}-${range[1]} 条数据`,
        onChange: pageNo => this.pageNoChange(pageNo),
        onShowSizeChange: (pageNo, pageSize) => this.pageSizeChange(pageNo, pageSize)
      },
      total: 0,
      list: []
    }
  },
  created () {
    this.reloadList()
  },
  filters: {
    formatAccountType (v) {
      if (v === 'user') {
        return '用户'
      }
      if (v === 'sale') {
        return '大使'
      }
      if (v === 'commune') {
        return '公社'
      }
      if (v === 'stage') {
        return '驿站'
      }
    }
  },
  methods: {
    moment,
    // 重置表单
    resetForm (formName) {
      this.form.name = ''
      this.form.gmtInvite = []
      this.form.gmtInviteStart = null
      this.form.gmtInviteEnd = null
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => {
    }) {
      this.pagination.current = 1
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => {
    }) {
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }, this.form)
      const { total, records } = await this.$store.dispatch('http', {
        api: 'queryInvitationStatistics',
        query: {
          beInviteType: 'sale'
        },
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      if (records) {
        records.forEach(item => {
          item.isLoading = false
        })
      }
      this.list = records
    },
    // 页码切换
    pageNoChange (pageNo) {
      this.pagination.current = pageNo
      this.loadList()
    },
    // 每页显示条数切换
    pageSizeChange (pageNo, pageSize) {
      this.pagination.pageSize = pageSize
      this.reloadList()
    },
    // 明细
    viewRecord (scope) {
      let inviteScene = 'stageSale'
      if (scope.accountType === 'sale') {
        inviteScene = 'sale'
      }
      this.$router.push({
        name: 'BeInvitedSaleList',
        params: {
          inviteId: scope.inviteId,
          inviteScene: inviteScene,
          breadName: scope.name + scope.phone,
          gmtInvite: this.gmtInvite
        }
      })
    },
    onChange1 (data) {
      this.form.gmtInviteStart = data[0] + ' 00:00:00'
      this.form.gmtInviteEnd = data[1] + ' 23:59:59'
    },
    disabledDate (current) {
      return current && current > moment().endOf('day')
    }
  }
}
</script>

<style lang="scss" module>
.container {
  .form__ct {
    padding: 10px 0;
  }

  .form__items {
    padding-top: 6px;
  }
}
</style>
